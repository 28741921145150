<template>
    <div class="mask-box" :style="{ backgroundColor: opacity == 1? '#f5f5f5':backgroundVal }">
         <div class="mask-content">
                <img src="@/assets/img/decorate/loading-icon.png" class="mask-sign"/>
                <img src="@/assets/img/decorate/loading-cicle.png" class="mask-cicle"/>
         </div>
    </div>
  </template>
  
  <script>
    export default {
      name: 'app',
      data () {
        return {
          show: true
        }
      },
      props: {
        loadingBol: {
          type: Boolean,
          default(){
            return true
          }
        },
        opacity: {
          type: [Number,String],
          default(){
            return 1
          }
        },
      },
      computed: {
          backgroundVal() {
            if(this.opacity.indexOf('rgba')!=-1){
              return this.opacity;
            }
            return `rgba(0, 0, 0, ${this.opacity})`;
          },
        },
      methods: {
      
      }
    }
  </script>
  
  <style lang="scss" scoped>
      .mask-box{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        background:#f5f5f5;
        left: 0;
        top: 0;
        z-index: 9999;
        @keyframes rotate-loop {
          0% {
            transform:  rotate(0deg);
          }
          100% {
            transform:  rotate(360deg);
          }
        }
        .mask-content{
          width: 90px;
          height: 90px;
          position: relative;
          >.mask-sign{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            animation: rotate-loop 4s linear infinite;
          }
          .mask-cicle{
            position: absolute;
            width: 70%;
            left: 15px;
            top: 33px;
          }
        }

     }
  </style>
  