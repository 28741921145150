import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import utils from './utils'
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/element-variables.scss';
import '@/styles/index.scss';
import defaultSettings from '@/settings'

import '@/permission' // permission control
import './icons'
Vue.use(ElementUI);

import ImTablePage from '@/components/ImTablePage/index'
Vue.use(ImTablePage)
import ImPagination from '@/components/ImPagination/index'
Vue.use(ImPagination)
import ImPageModal from '@/components/ImPageModal/index'
Vue.use(ImPageModal)
import ImPicZoom from '@/components/ImPicZoom/index'
Vue.use(ImPicZoom)
import ImDrawer from '@/components/ImDrawer'
Vue.use(ImDrawer)
import ImSearchPad from '@/components/ImSearchPad/index'
Vue.use(ImSearchPad)
import ImSearchPadItem from '@/components/ImSearchPadItem/index'
Vue.use(ImSearchPadItem)
import ImToolBar from '@/components/ImToolBar/index'
Vue.use(ImToolBar)
import ImConfirmPopup from '@/components/ImConfirmPopup/index'
import { showLoading, hideLoading } from '@/utils/loadingApi';
// import VConsole from 'vconsole'
// const vConsole = new VConsole()


Vue.use(ImConfirmPopup)
Vue.config.productionTip = false;
Vue.prototype.$util = utils;
Vue.prototype.showLoading =showLoading;
Vue.prototype.hideLoading =hideLoading;
Vue.prototype.defaultSettings = defaultSettings
// 设置公共样式
const root = document.documentElement;
// root.style.setProperty('--main-color', 'rgba(222, 123, 68, 1)');
root.style.setProperty('--main-color', '#1DB2A5');
// root.style.setProperty('--main-background-color', 'rgba(255, 141, 26, 1)');
root.style.setProperty('--main-background-color', '#1DB2A5');
root.style.setProperty('--main-fooot-background-color', 'rgb(244, 251, 250)');


// root.style.setProperty('--font-size-base', '120px');


Vue.filter('getGoodsName', utils.getGoodsName)
Vue.filter('toFixed', utils.toFixed)
Vue.directive('throttle', {
  bind: (el, binding) => {
    let throttleTime = binding.value; // 防抖时间
    if (!throttleTime) { // 用户若不设置防抖时间，则默认2s
      throttleTime = 1000;
    }
    let cbFun;
    el.addEventListener('click', event => {
      if (!cbFun) { // 第一次执行
        cbFun = setTimeout(() => {
          cbFun = null;
        }, throttleTime);
      } else {
        event && event.stopImmediatePropagation();
      }
    }, true);
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
