import request from '@/utils/request'
import defaultSettings from '@/settings'

/**
 * @description 客服联系方式
 */
export function getServiceTel() {
    return request({
        url: `/${defaultSettings.businessPre}/pc/front/user/serviceTel`,
        method: 'get'
    })
}

/**
 * @description 获取企业基本信息
 */
export function saveFrontVerification(data) {
    return request({
        url: `/${defaultSettings.businessPre}/pc/front/user/pcFrontVerification`,
        method: 'POST',
        data
    })
}

/**
 * @description 强制修改密码
 */
export function updatePcFrontPassword(data) {
    return request({
        url: `/${defaultSettings.businessPre}/pc/front/user/updatePcFrontPassword`,
        method: 'POST',
        data
    })
}

/**
 * @description 退出登录
 */
export function deleteUserLogOut(data) {
    return request({
        url: `/${defaultSettings.businessPre}/pc/front/user/logout`,
        method: 'DELETE'
    })
}
/**
 * @description 修改密码
 */
 export function updatePassword(data) {
    return request({
        url: `/${defaultSettings.businessPre}/pc/front/user/updatePassword`,
        method: 'POST',
        data
    })
}
