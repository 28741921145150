// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* element variables*/\n/* theme color */\n.im-pagination {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  font-size: 14px !important;\n  color: #595959;\n}\n.im-pagination .main-info {\n    font-size: 13px;\n    line-height: 32px;\n}\n.im-pagination .el-input_inner {\n    height: 24px;\n}\n.im-pagination .el-pager li {\n    height: 24px;\n}\n.im-pagination .el-pagination.is-background .btn-prev, .im-pagination .el-pagination.is-background .btn-next, .im-pagination .el-pagination.is-background .el-pager li {\n    background-color: transparent;\n    min-width: 24px;\n    height: 24px;\n    line-height: 24px;\n    margin: 2px;\n}\n.im-pagination .el-pagination__jump {\n    margin-left: 11px;\n}\n.im-pagination ::v-deep .el-input__inner {\n    height: 24px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "208px"
};
module.exports = exports;
