import request from '@/utils/request'
import defaultSettings from '@/settings'

/**
 * @description 获取所有商家(未登录情况下使用)
 */
export function getBusiness(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/user/frontOrgList`,
    method: 'get',
    params
  })
}

/**
 * @description 获取所有机构
 */
export function pageSelectEttleAccount(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/user/pageSelectEttleAccount`,
    method: 'post',
    data
  })
}

/**
 * @description 切换当前商家
 */
export function changeBusiness(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/user/changeOrg`,
    method: 'get',
    headers: {
      'x-merchant-code-header': params
    }
  })
}

/**
 * @description 切换当前机构
 */
export function changAccount(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/user/selectBranch`,
    method: 'get',
    params: {
      ettleAccountId: params.ettleAccountId,
      buyersUserId: params.userId
    }
  })
}

/**
 * @description 通用配置查询列表
 */
export function pageSetupList(data) {
  return request({
      url: `/${defaultSettings.businessPre}/pageSetup/front/pageSetupList`,
      method: 'get',
      data
  })
}
