var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mask-box" }, [
    _c(
      "div",
      { staticClass: "mask-content" },
      [
        _vm.cartType == "success"
          ? [
              _c("img", {
                attrs: { src: require("@/assets/img/detail/success.png") },
              }),
              _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tipsStr))]),
            ]
          : [
              _c("img", {
                attrs: { src: require("@/assets/img/detail/fail.png") },
              }),
              _c("div", { staticClass: "tip fail" }, [
                _vm._v(_vm._s(_vm.tipsStr)),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }