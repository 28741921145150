var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empty-box" }, [
    _c(
      "div",
      [
        _c("el-image", {
          staticStyle: { width: "290px", height: "290px" },
          attrs: { src: _vm.imgUrl, fit: "fill" },
        }),
        _c("p", { staticClass: "empty-tip" }, [_vm._v(_vm._s(_vm.empytTip))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }