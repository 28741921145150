module.exports = {

  title: '药药有医药商城',
  businessPre: 'medhubBusinessService',
  reportPre: 'medhubReportService',
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: true,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',

  /**
   * @type {number}
   * @description 每条页数
   */
  pageSize: 20,

  /**
   * @type {string}
   * @description md5 固定盐
   */
  salt: '!@#$%^&*sparkMEDHUB'
}
