<template>
  <div class="header-box">
    <div :class="isType=='WH'?'header-title':'header-title notice-title'" v-if="tipsContent.length>0 && noticeBol"> 
      <span style="font-weight: bolder;">{{isType=='WH'?'站点维护':'商城公告'}}&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span> {{tipsContent}}
      <img src="@/assets/img/icon/guide.png" @click="messageLinkTap" v-if="isType=='WH'"/>
      <span v-else @click="removeNoticeTap" class="remove-sign">X</span>
    </div>
    <div class="banner">
      <img
        v-if="header.PC_DBGG && header.PC_DBGG.length"
        :src="header.PC_DBGG[0].sysFileUrl"
        @click="bindUrl(header.PC_DBGG[0].link)"
        lazy
      />
    </div>
    <div
      class="nav"
      style="
        width: 1100px;
        background-color: rgba(245, 245, 245, 1);
        border-bottom: 1px solid #eee;
      "
    >
      <ul
        class="detail"
        style=" -webkit-backface-visibility: hidden"
      >
        <li class="first" v-if="allBusiness.length">
          <el-dropdown v-if="allBusiness.length > 1">
            <span class="el-dropdown-link">
              <!--              <img class="icon" src="../../assets/img/icon/icon-yiyuan.png" alt>-->
              <span v-if="org">
                {{ org.orgNm }}
              </span>
              <span v-else>
                {{ allBusiness[businessIndex].orgNm }}
              </span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" style="padding-right: 15px">
              <el-dropdown-item
                @click.native="changOrg(item, index)"
                :style="{ color: item.orgNm === org.orgNm ? 'rgba(108, 108, 108, 1)' : '' }"
                v-for="(item, index) in allBusiness"
                :key="index"
                class="noSelect"
                :class="{
                  'checkBol-header':
                    businessIndex === index || item.orgNm === org.orgNm,
                }"
              >
                <span :class="item.orgNm === org.orgNm ? 'itemName' : ''">{{
                  item.orgNm
                }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span v-else style="color: rgba(108, 108, 108, 1)">
            <!-- <img class="icon" src="../../assets/img/icon/icon-yiyuan.png" alt /> -->
            {{ allBusiness[0].orgNm }}
            <!--            {{ activeIndex }}-->
          </span>
        </li>

        <li class="first" v-if="!buyersVo.buyersNm">
          <span class="text-color-red">
            <router-link to="/login" style="margin-left: 15px"
              >您好，请登录 <i type="person"></i
            ></router-link>
          </span>
          <!--          <router-link to="/register"
            >免费注册 <i type="person-add"></i
          ></router-link>-->
        </li>

        <li class="first" v-else>
          <span v-if="userType === 'SALESMAN'" class="welcome-text">
            您好，<router-link style="padding: 0 10px 0 0" to="/member-center"
              ><b>{{ buyersVo.salesmanName }}</b></router-link
            >
            <div style="display: inline-flex">
              <em
                :title="`${accountId.buyersNm}${
                  accountId.ettleAccountNm == '默认分支机构'
                    ? ''
                    : '-' + accountId.ettleAccountNm
                }`"
                >{{ accountId.buyersNm
                }}{{
                  !accountId.ettleAccountNm ||
                  accountId.ettleAccountNm == "默认分支机构"
                    ? ""
                    : `-${accountId.ettleAccountNm}`
                }}</em
              >
            </div>
          </span>

          <el-dropdown v-else-if="buyersVoList.length > 1">
            <span class="welcome-text"
              >您好，<router-link to="/member-center/home">
                <b :title="buyersVo.buyersNm + '-' + accountId.ettleAccountNm"
                  >{{ buyersVo.buyersNm
                  }}{{
                    accountId.ettleAccountNm == "默认分支机构"
                      ? ""
                      : `-${
                          accountId.ettleAccountNm
                            ? accountId.ettleAccountNm
                            : ""
                        }`
                  }}</b
                >
              </router-link>
              <i
                class="el-icon-arrow-down el-icon--right"
                style="color: var(--main-color)"
              ></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="changeMechanism(item, index)"
                :style="{
                  color: item.buyersId === buyersVo.buyersId ? 'var(--main-color)' : '',
                }"
                v-for="(item, index) in buyersVoList"
                :key="index"
                class="noSelect"
                :class="{
                  'checkBol-header': item.buyersId === buyersVo.buyersId,
                }"
              >
                <span
                  :class="item.buyersId === buyersVo.buyersId ? 'itemName' : ''"
                  >{{ item.buyersNm }}</span
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <span v-else class="welcome-text"
            >您好，<router-link to="/member-center/home">
              <b :title="buyersVo.buyersNm + '-' + accountId.ettleAccountNm"
                >{{ buyersVo.buyersNm
                }}{{
                  accountId.ettleAccountNm == "默认分支机构"
                    ? ""
                    : `-${
                        accountId.ettleAccountNm ? accountId.ettleAccountNm : ""
                      }`
                }}</b
              >
            </router-link></span
          >

          <span
            class="welcome-text cur"
            v-if="userType !== 'BUYERS' || account.length !== 1"
            @click="dialogTableVisible = !dialogTableVisible"
          >
            {{ userType === "SALESMAN" ? "切换客户" : "切换分支机构" }}
          </span>
          <span class="welcome-text cur" @click="logout">退出</span>
        </li>
        <el-dialog
          class="dialog-table"
          :title="userType === 'SALESMAN' ? '选择客户' : '切换分支机构'"
          width="1100px"
          :visible.sync="dialogTableVisible"
          :close-on-click-modal="false"
          :show-close="true"

          :close-on-press-escape="
            userType === 'SALESMAN' || accountId.ettleAccountNm != null
          "
        >
          <h4 v-if="userType === 'SALESMAN'">请选择以下你需服务的客户</h4>
          <h4 v-else>请选择您要采购的分支机构</h4>
          <el-form
            :inline="true"
            :model="searchForm"
            @submit.native.prevent
            class="demo-form-header"
            size="small"
          >
            <el-form-item prop="orderNum">
              <el-input
                style="width: 470px"
                v-model="searchForm.searchField"
                :placeholder="
                  userType === 'SALESMAN'
                    ? '请输入客户名称/客户ERP编码/分支机构名称/分支机构编码'
                    : '请输入分支机构名称/分支机构编码/联系手机'
                "
                @keyup.enter.native.stop.prevent="handleSearch"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="handleSearch"
               v-throttle="1500"
               class="theme-btn"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            :data="account"
            size="mini"
            :cell-style="{ padding: '0' }"
            border
            style="margin-bottom: 30px"
          >
            <el-table-column
              type="index"
              label="序号"
              width="55"
              fixed="left"
              align="center"
            />
            <el-table-column
              v-if="userType === 'SALESMAN'"
              property="erpCode"
              label="客户ERP编码"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  style="
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                  "
                >
                  <div>{{ scope.row.erpCode }}</div>
                  <div class="erpTab" v-if="scope.row.limitCreditDate <= 0">
                    账期逾期
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="userType === 'SALESMAN'"
              property="buyersNm"
              label="客户名称"
              show-overflow-tooltip
            />
            <el-table-column
              property="code"
              label="分支机构编码"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  scope.row.ettleAccountNm == "默认分支机构"
                    ? "-"
                    : scope.row.code
                }}
              </template>
            </el-table-column>
            <el-table-column
              property="ettleAccountNm"
              label="分支机构"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  scope.row.ettleAccountNm == "默认分支机构"
                    ? "-"
                    : scope.row.ettleAccountNm
                }}
              </template>
            </el-table-column>
            <el-table-column
              property="userName"
              label="采购员"
              width="120"
              show-overflow-tooltip
            />
            <!--            <el-table-column property="userMobile" label="联系手机" width="120"/>-->
            <el-table-column
              v-if="userType !== 'SALESMAN'"
              prop="district"
              label="所在地区"
              width="200"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.province">{{ scope.row.province }}</span>
                <span v-if="scope.row.city">{{ "-" + scope.row.city }}</span>
                <span v-if="scope.row.district">{{
                  "-" + scope.row.district
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="userType === 'SALESMAN'"
              property="customerTypeName"
              label="客户类型"
              width="120"
            />
            <el-table-column width="64" label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button
                  v-if="userType === 'SALESMAN'"
                  type="text"
                  size="small"
                  @click="handleChangeAccount(scope.row)"
                  v-throttle
                  >选择
                </el-button>
                <el-button
                  v-else
                  type="text"
                  size="small"
                  @click="changAccount(scope.row)"
                  v-throttle
                  >选择</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <im-pagination
            v-if="userType === 'SALESMAN'"
            :hide-on-single-page="false"
            :isShowMainInfo="true"
            :total="total"
            :current-page.sync="currentPage"
            :page-size.sync="pageSize"
            @pagination="handlePagination"
          />
        </el-dialog>
        <!--资质逾期，账期逾期-->
        <el-dialog
          width="600px"
          class="dialog-license"
          :title="zzOutOfTime ? '资质超期提醒' : '超账期提醒'"
          :visible.sync="dialogLicenseVisible"
        >
          <div class="flex" v-if="zzOutOfTime">
            <!--            <div class="img">-->
            <!--              <img :src="license.fileUrl" alt>-->
            <!--            </div>-->
            <div class="text">
              <h5>温馨提示</h5>
              <!--              <p>您有<span>{{license.failureSum}}</span>张证照{{license.isFailure === 'Y' ? '已' : '即将'}}过期</p>-->
              <p v-if="license.isFailure === 'Y'">
                您的证照<span
                  v-for="(item, index) in outTimeAptitudesList"
                  :key="index"
                  >{{
                    index === outTimeAptitudesList.length - 1
                      ? item.licenseNm
                      : item.licenseNm + "，"
                  }}</span
                >已过期
              </p>
              <p v-else>
                您的证照<span
                  v-for="(item, index) in noMoreTimeAptitudesList"
                  :key="index"
                  >{{
                    index === noMoreTimeAptitudesList.length - 1
                      ? item.licenseNm
                      : item.licenseNm + "，"
                  }}</span
                >即将过期
              </p>
              <p>请及时联系业务员更换，以免影响您的正常采购！</p>
              <el-button size="small" type="primary" @click="handleConfirm"
                >查看详情</el-button
              >
            </div>
          </div>
          <div class="text_CQ" v-else>
            <h5>温馨提示</h5>
            <p>您有逾期未付款的订单</p>
            <p>请尽快付款结算，以免影响您的正常采购！</p>
            <el-button size="small" type="primary" @click="handleConfirm"
              >查看详情</el-button
            >
          </div>
        </el-dialog>
      </ul>
      <ul
        class="location"
        style=" -webkit-backface-visibility: hidden"
      >
        <li class="top" v-show="$route.path !== '/'">
          <div>
            <router-link to="/" style="color:var(--main-color)">
              <!--                <img src="../../assets/img/icon/icon-shangchenshouye.png" alt>-->
              返回商城首页
            </router-link>
          </div>
        </li>
        <!-- <li class="top">
          <div>
            <router-link to="/drug-test" style="color: #1FBFBE">
              药检单下载
            </router-link>
          </div>
        </li> -->
        <li class="top">
          <div>
            <a @click="goShop">
              <svg-icon icon-class="shopping" class="icon-shopping" />
              <!-- <img src="../../assets/img/icon/icon-gouwuche (2).png" alt style="width:13px;"/> -->
              购物车({{ cartNum }})
            </a>
          </div>
        </li>
        <li>
           <router-link
                  :to="
                    userType === 'SALESMAN'
                      ? '/member-center'
                      : '/member-center/home'
                  "
                  >买家中心</router-link
                >
        </li>
        <li @click="collection()" :class="userType?'':'noborder'">
          <router-link to="#">收藏本站</router-link>
        </li>
        <li v-if="userType" @click="logout" :class="userType?'noborder':''">
          <!-- <router-link to="#">登出11</router-link> -->
           <!-- <span class="logout-span">登出</span> -->
        </li>
        <!-- <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              会员中心<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link
                  :to="
                    userType === 'SALESMAN'
                      ? '/member-center'
                      : '/member-center/home'
                  "
                  >个人中心</router-link
                >
              </el-dropdown-item>
              <el-dropdown-item v-if="userType" @click.native="logout"
                >登出</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </li> -->
      </ul>
    </div>

    <!-- 修改密码弹窗 -->
    <el-dialog
      width="30%"
      custom-class="psw-dialog"
      :visible.sync="pswVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="pswRuleForm"
        label-width="100px"
        :model="pswForm"
        :rules="pswRules"
      >
        <el-form-item label="旧密码" prop="oldPsw">
          <el-input
            v-model="pswForm.oldPsw"
            placeholder="请输入旧密码"
            show-password
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPsw">
          <el-input
            v-model="pswForm.newPsw"
            placeholder="请输入新密码"
            show-password
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPsw">
          <el-input
            v-model="pswForm.confirmPsw"
            placeholder="请输入确认密码"
            show-password
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="submitPswForm" v-throttle
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getAccount, getToken, getUserType, setAccount} from "@/utils/auth";
import {pageSelectEttleAccount} from "@/api/org";
import {getMaintenanceBulletin, getMyAptitudes} from "@/api/member-center/personal-center";
import {cancelAllApi} from "@/utils/request";
import {selectNewBranchBuyers} from "@/api/member-center/personal-center";
import {getInfo} from "@/api/user";
import {encodeMd5} from "@/utils/encode-md5";
import {validPassword} from "@/utils/validate";
import {deleteUserLogOut, updatePcFrontPassword} from "@/api/login/forget-password";
import { setUserMessage } from "@/utils/auth";
export default {
  name: "Header",
  props: ["checkPadTime"],
  data() {
    const newPsw = (rule, value, callback) => {
      if (!validPassword(value)) {
        return callback(new Error("请输入8-20位大小写字母、数字和字符"));
      } else {
        callback();
      }
    };
    const confirmPsw = (rule, value, callback) => {
      if (value !== this.pswForm.newPsw) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      account: [],
      pageSize: 10,
      businessIndex: 0,
      dialogTableVisible: false,
      dialogLicenseVisible: false,
      dialogTableClose: false,
      total: 0,
      searchForm: {
        searchField: "",
      },
      outTimeAptitudesList: [],
      noMoreTimeAptitudesList: [],
      currentPage: 1,
      userType: getUserType(),
      buyersVoList: [],
      pswVisible: false,
      pswForm: {
        oldPsw: "",
        newPsw: "",
        confirmPsw: "",
      },
      pswRules: {
        oldPsw: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPsw: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: newPsw },
        ],
        confirmPsw: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: confirmPsw },
        ],
      },
      tipsContent:'',
      tipsLink:'',
      isType:'',
      noticeBol:true,
    };
  },
  mounted() {
    //如果未登录不需要请求
    // this.isLogin =
    //console.log("getToken(===", getToken());
    if (getToken()) {
      getInfo().then((res) => {
        if(res.data){
          setUserMessage({
            userId:res.data.userId,
            userName:res.data.name,
          })
        }
        this.buyersVoList = Array.isArray(res.data.buyersVoList)
          ? res.data.buyersVoList
          : [];
      });
      const checkPadTime = localStorage.getItem("checkPadTime");
      if (checkPadTime && checkPadTime == 1) {
        this.$alert(
          "您的账号已经一个月没有修改密码，为了保障你的账户安全，请修改密码",
          "提示",
          {
            confirmButtonText: "确定",
            showClose: false,
            callback: (action) => {
              this.pswVisible = true;
            },
          }
        );
      }
    }
  },

  methods: {
    removeNoticeTap(){
      this.noticeBol=false
    },
    judgeTime(){
      getMaintenanceBulletin().then(res=>{
        console.log(res)
        if(res.data&&res.data.bulletinCont){
          this.tipsContent= res.data.bulletinCont;
          this.tipsLink=res.data.externalUrlAddress;
          this.isType=res.data.isType
        }

      })
    },
    messageLinkTap(){
      location.href=this.tipsLink?this.tipsLink:'#'
    },
    submitPswForm() {
      this.$refs.pswRuleForm.validate(async (valid) => {
        if (valid) {
          const data = {
            loginId: sessionStorage.getItem("loginId"),
            oldPassword: encodeMd5(this.pswForm.oldPsw),
            password: encodeMd5(this.pswForm.newPsw),
          };
          updatePcFrontPassword(data)
            .then((res) => {
              if (res.code == "0") {
                this.pswVisible = false;
                this.$message.success("修改密码成功");
                this.$store.dispatch("user/logout").then((_) => {
                  localStorage.removeItem("checkPadTime");
                  sessionStorage.haveAccount = false;
                  sessionStorage.removeItem("loginId");
                  sessionStorage.removeItem("userMobile");
                  this.$router.push(`/login`);
                  cancelAllApi();
                });
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        } else {
          return false;
        }
      });
    },
    handleSearch() {
      this.getAccountApi();
    },
    handlePagination(data) {
      this.currentPage = Math.min(
        this.currentPage,
        Math.ceil(this.total / data.pageSize)
      );
      this.getAccountApi();
    },
    handleConfirm() {
      this.$router.push(`/member-center/personal-center/my-aptitudes`);
      this.dialogLicenseVisible = false;
    },
    goShop() {
      if (this.$route.name === "ShoppingCart") {
        this.$router.push({
          path: "/shopping-cart",
          query: {
            title: "购物车",
            currentView: "ShoppingCartList",
            active: 0,
            random: Math.random(),
          },
        });
      } else {
        this.$router.push(`/shopping-cart`);
      }
    },
    async logout() {
        console.log("....vue...logout")
      await  deleteUserLogOut()
        this.$store.dispatch("user/logout").then((_) => {
        sessionStorage.haveAccount = false;
        sessionStorage.removeItem("loginId");
        sessionStorage.removeItem("userMobile");
        this.$router.push(`/login`);
        cancelAllApi();
      });
    },
    changOrg(item, index) {
      this.$store.dispatch("user/changeBusiness", item).then((_) => {
        this.$store.dispatch("user/setRefreshModule", Math.random());
        window.history.go(0);
        this.$message.success("切换成功");
        this.businessIndex = index;
      });
    },
    //切换采购单元
    changeMechanism(item, index) {
      selectNewBranchBuyers(item.buyersId, this.selectEttleAccountId).then(
        (res) => {
          window.location.reload();
          sessionStorage.haveAccount = true;
          // setAccount(item)
          // this.$store.dispatch('user/setRefreshModule', Math.random())
          this.$store.dispatch("user/getInfo");
          // setTimeout(()=>{
          // },500)
          this.$message.success("切换成功");
        }
      );
    },
    handleChangeAccount(row) {
      if (row.limitCreditDate <= 0) {
        this.$confirm("该客户账期已逾期，确认继续？", "提示")
          .then((res) => {
            this.changAccount(row);
          })
          .catch((err) => {});
      } else {
        this.changAccount(row);
      }
    },
    changAccount(row) {
      this.$store.dispatch("user/changAccount", row).then((_) => {
        this.$message.success("切换成功");
        sessionStorage.haveAccount = true;
        setAccount(row);
        this.$store.dispatch("user/setRefreshModule", Math.random());
        if (
          (this.license.isFailure === "Y" || this.license.isOverdue === "Y") &&
          this.userType !== "SALESMAN"
        ) {
          this.dialogLicenseVisible = true;
        }
        this.dialogTableVisible = false;
      });
    },
    getAccountApi() {
      let pageSize = 10;
      pageSize =
        this.userType === "SALESMAN" || this.userType === ""
          ? this.pageSize
          : 10000;
      return new Promise((resolve, reject) => {
        pageSelectEttleAccount({
          pageSize: pageSize,
          pageNum: this.currentPage,
          condition: this.searchForm,
        })
          .then((response) => {
            const { data } = response;
            this.account = data.records;
            this.total = data.total;
            resolve(data.records);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAptitudesList() {
      let licenseNm = [
        "《营业执照》",
        "《医疗机构执业许可证》",
        "《药品经营许可证》",
      ];
      getMyAptitudes().then((res) => {
        const { data } = res;
        this.outTimeAptitudesList = data.filter(
          (item) =>
            licenseNm.includes(item.licenseNm) && item.status === "已过期"
        );
        this.noMoreTimeAptitudesList = data.filter(
          (item) =>
            licenseNm.includes(item.licenseNm) && item.status === "即将过期"
        );

        let date = "2021-07-01";
        let endTime = this.$util.getTime(date, "timeStamp-13");
        let currentTime = new Date().getTime();
        if (currentTime < endTime) {
          this.noMoreTimeAptitudesList = this.noMoreTimeAptitudesList.filter(
            (item) =>
              item.licenseNm !== "《营业执照》" && item.licenseNm !== "营业执照"
          );
        }

        if (!this.noMoreTimeAptitudesList.length) {
          this.$store.state.license = {
            ...this.$store.state.license,
            isOverdue: "N",
          };
        }
        if (
          (this.outTimeAptitudesList.length > 0 ||
            this.noMoreTimeAptitudesList.length > 0) &&
          this.userType !== "SALESMAN"
        ) {
          this.dialogLicenseVisible = true;
        }
      });
    },
    collection() {
      const sTitle = this.$route.meta.title;
      const sURL = window.location.href;
      try {
        window.external.addFavorite(sURL, sTitle);
      } catch (e) {
        try {
          window.sidebar.addPanel(sTitle, sURL, "");
        } catch (e) {
          alert("请使用ctrl+D/command+D进行添加");
        }
      }
    },
  },
  created() {
    this.judgeTime();
    if (getToken()) {
      this.getAptitudesList();
      this.getAccountApi()
        .then((res) => {
          if (
            getAccount() &&
            res.filter((item) => item.toString() === getAccount().toString())
          ) {
            this.$store.dispatch("user/changAccount", getAccount(), false);
          } else {
            if (res.length == 1) {
              this.$store
                .dispatch("user/changAccount", res[0], false)
                .then((res) => {
                  sessionStorage.haveAccount = true;
                });
            } else {
              //需要判断是否为主账号且为采购员
              // this.dialogTableVisible = !(
              //   this.license.isDefault === "N" && this.userType === "BUYERS"
              // );
            }
          }
        })
        .catch(() => {});
    }
  },
  watch: {
    buyersVo(newValue, oldValue) {
      if (this.license.isFailure === "Y" && this.userType !== "SALESMAN") {
        this.dialogLicenseVisible = true;
      }
    },
    "account.length"(newValue) {
      this.dialogTableClose = !newValue;
    },
    org(newVal) {
      this.businessIndex = this.allBusiness.indexOf(this.org);
    },
    cartNum(newVal) {
      this.GetCartNum = newVal;
    },
    proxyEndDate(newVal) {
      if (newVal && this.userType === "SALESMAN") {
        let endTime = new Date(newVal.replace(/-/g, "/")).getTime();
        let exTime = endTime - new Date().getTime();
        if (exTime < 0) {
          this.$message.error("该客户委托书已过期");
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "allBusiness",
      "selectEttleAccountId",
      "buyersVo",
      "org",
      "cartNum",
      "license",
      "accountId",
      "proxyEndDate",
      "header",
    ]),
    zzOutOfTime() {
      return (
        this.license.isFailure === "Y" ||
        (this.license.isAboutFailure === "Y" &&
          !!this.noMoreTimeAptitudesList.length)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  img {
    display: block;
    width: 100%;
  }
}
.icon-shopping{
   font-size: 16px;
   color: var(--main-color) !important;
}
.erpTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: red;
  height: 20px;
  font-size: 12px;
  border: 1px solid red;
  padding: 1px 3px;
  border-radius: 3px;
  margin-left: 5px;
}

.noSelect {
  // width: 72px;
  // height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  // line-height: 17px;
}

.itemName {
  // background: var(--main-background-color);
  // margin: 0 0 0 20px;
  //&::after{
  //  content: '√';
  //  margin: 0 0 0 20px;
  //}
}

.header-box {
  width: 100%;
  background-color: rgba(245, 245, 245, 1);
  .header-title{
    height: 40px;
    width: 100%;
    background-color: #090822;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    color:#fefefe;
    >img{
      width: 17px;
      height: 17px;
      margin-left: 30px;
    }
  }
  .notice-title{
    background: rgba(51, 92, 99, 1);
    color:#fff;
    .remove-sign{
      font-size: 20px;
      margin-left: 10px
    }

  }
  .nav {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      float: left;
      line-height: 30px;
    }

    a {
      color: rgba(108, 108, 108, 1) !important;
      padding-left: 20px;
      cursor: pointer;
      font-size:11px !important;
      font-family:'HarmonyOS Sans SC';
      &:hover {
        color: var(--main-color);
      }
    }

    .first {
      font-size: 11px;
      color: rgba(108, 108, 108, 1);

      ::v-deep .el-dropdown {
        font-size: 13px;
        cursor: pointer;
        color: var(--main-color);

        .el-icon--right {
          margin-left: 0;
        }
      }

      .icon {
        width: 13px;
        height: 13px;
        vertical-align: text-top;
      }

      .welcome-text {
        margin-left: 12px;
        color: #333;

        b,
        em {
          max-width: 260px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        b {
          color: var(--main-color);
          font-size: 11px;
        }

        &.cur {
          cursor: pointer;

          &:hover {
            color: var(--main-color);
          }
        }
      }

      .text-color-red {
        a {
          color: var(--main-color) !important;
          margin-left: 6px;
        }
      }

      a:first-child {
        padding-left: 10px;
        border-left: none;
        display: inline-flex;
      }
    }

    .dialog-table {
      h4 {
        font-size: 14px;
        color: #666;
        padding-bottom: 12px;
      }

      ::v-deep .el-dialog {
        text-align: left;

        .el-dialog__body {
          padding: 20px;
        }
      }
    }

    .dialog-license {
      ::v-deep .el-dialog {
        text-align: left;

        .el-dialog__body {
          padding: 40px;

          .text_CQ {
            text-align: center;

            h5 {
              margin-bottom: 20px;
            }

            p {
              margin-top: 2px;
            }

            .el-button {
              margin-top: 20px;
            }
          }

          .flex {
            display: flex;
            align-items: center;

            .img {
              width: 180px;
              height: 180px;
              line-height: 180px;

              img {
                vertical-align: middle;
                width: 100%;
                height: 100%;
              }
            }

            .text {
              padding-left: 20px;

              h5 {
                font-size: 17px;
                padding-bottom: 10px;
              }

              p {
                color: #888;
                line-height: 1.4;

                span {
                  color: #fa6400;
                }
              }

              .el-button {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }

    .location {
      .top img {
        position: relative;
        top: 1px;
      }

      a {
        color: #222222;
        font-size: 11px;
        padding-right: 20px;
        // border-right: 1px solid #ddd;

        &:hover {
          color: var(--main-color)
        }
      }
      .logout-span{
        color: #6c6c6c;
        font-size: 11px;
        padding-right: 20px;
        // border-right: 1px solid #ddd;

        &:hover {
          color: var(--main-color);
        }
      }
      .noborder{
        a {
          border-right: 0;
          padding-right: 0;
        }
        .logout-span{
          border-right: 0;
          padding-right: 0;
        }
      }

      .el-dropdown-link {
        cursor: pointer;
        font-size: 12px;
        color: #222;
        padding-left: 15px;
      }

      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }
}

.el-dropdown-menu {
  top: 15px !important;
  max-height: 90%;
  padding: 10px;
  overflow: auto;
}

.demo-form-header ::v-deep {
  .el-form-item {
    margin-bottom: 10px;
  }

  .el-button--mini {
    padding: 8px 15px;
  }
}

::v-deep .psw-dialog {
  .el-dialog__header {
    background: #fff;
  }
  .el-dialog__body {
    padding: 0 20px;
  }
}
</style>

<style lang="scss">
.el-dropdown-menu__item.checkBol-header {
  color: var(--main-color);
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 5px;
    height: 9px;
    border-color: var(--main-color);
    border-style: solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    right: -7px;
    top: 50%;
    margin-top: -5px;
  }
}
</style>
