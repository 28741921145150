import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import router from "@/router";
import { getToken, getBus } from "@/utils/auth";
import defaultSettings from "@/settings";
import { showLoading, hideLoading } from '@/utils/loadingApi';
const $message = Message;
let messageFlag = false;

const NO_CANCEL_URLS = [
  `/${defaultSettings.businessPre}/district/listByParentCode`,
  `/${defaultSettings.businessPre}/pc/order/pageIndexOrder`,
];

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 100000,
  "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
});

let pending = [];
const CancelToken = axios.CancelToken;
const requsetPending = (config) => {
  if (NO_CANCEL_URLS.includes(config.url)) {
    return;
  }
  for (const i in pending) {
    if (pending[i].url === config.url && !config.notCancelXhr) {
      // console.log('cancel url:', config.url)
      pending[i].f();
    }
  }
};
const responsePending = (config) => {
  for (const i in pending) {
    if (pending[i].url === config.url) {
      pending.splice(i, 1);
    }
  }
};

service.interceptors.request.use(
  (config) => {
    if (config.url.includes("medhub")){
      config.url = '/api'+config.url;
    }
    requsetPending(config);
    config.cancelToken = new CancelToken(function executor(c) {
      pending.push({
        url: config.url,
        f: c,
      });
    });
    if (!config.headers || !config.headers["Client-API-Version"]) {
      config.headers["Client-API-Version"] = 1;
    }

    if (
      config.url.substring(0, config.url.indexOf("?")) !==
      `/${defaultSettings.businessPre}/payment/front/alipay/receive`
    ) {
      if (store.getters.token) {
        config.headers["x-access-token-header"] = getToken();
      } else if (getBus()) {
        config.headers["x-front-org-id-header"] = getBus().orgId;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.config.responseType === "arraybuffer") {
      return response;
    }
    if (response.config.responseType === "blob") {
      return response;
    }
    responsePending(response.config);
    const res = response.data;
    if (res.code != 0) {
      if (res.msg && res.msg.indexOf("限购") != -1) {
        return Promise.reject(res);
      }
      if (!messageFlag) {
        messageFlag = true;
        let message = (res && (res.message || res.msg)) || "Error";
        console.log(message);
        if (message === "GENERAL") message = "服务器维护中，请稍后再试";
        if (message === "SHORTCIRCUIT") message = "网站更新中，请稍后再试";
        if(message.indexOf('】行库存不足，可销售库存')!=-1 && (message.indexOf('商品第【')!=-1)){
          showLoading('','fail',message);
          setTimeout(()=>{
            hideLoading();
            messageFlag = false;
          },3200)
        }else{
         let typeName='error'
         response.config.url.indexOf('pc/personalCenter/customerOverview')!=-1?typeName='warning':''
          $message({
            message: message ? message : "",
            type: typeName,
            duration: 5 * 1000,
            onClose: () => {
              messageFlag = false;
            },
          });
        }
       
      }

      if (
        res.msg === "登录超时" ||
        res.code === "401" ||
        res.code === "403" ||
        res.code === "6" ||
        res.msg === "无该用户信息" ||
        res.msg === "登录已过期，请重新登录"
      ) {
        store.dispatch("user/logout");
        sessionStorage.haveAccount = false;
        if (router.currentRoute.path !== "/login") {
          router.push(`/login`);
        }
      }
      return Promise.reject(res);
    } else {
      const token = response.headers["x-access-token-header"];
      token && store.dispatch("user/toSetToken", token);
      return res;
    }
  },
  (error) => {
    const err =
      error.response && error.response.data
        ? error.response.data
        : error.response;
    console.log(error);
    if (!messageFlag && err) {
      messageFlag = true;
      let message = (err && (err.msg || err.message)) || "Error";
      if (message === "GENERAL") message = "服务器维护中，请稍后再试";
      $message({
        message: message || "",
        type: "error",
        duration: 5 * 1000,
        onClose: () => {
          messageFlag = false;
        },
      });
    }

    return Promise.reject(error);
  }
);

//清空所有请求，退出账号时调用
export const cancelAllApi = () => {
  pending.forEach((item, index) => {
    item.f();
  });
  pending = [];
};
export default service;
