import router from "./router";
import store from "./store";
import NProgress from "nprogress";
import { Message } from "element-ui";
import "nprogress/nprogress.css";
import { getToken, getBus, setBus, getAccount } from "@/utils/auth";
import getPageTitle from "@/utils/get-page-title";
import axios from "axios";
import fa from "element-ui/src/locale/lang/fa";
const $message = Message;
NProgress.configure({ showSpinner: false });

localStorage.app_version = JSON.stringify({
  version: "init",
  t: new Date().getTime(),
});

const getVersion = () => {
  return axios.get(`/js/version.json?t=${new Date().getTime()}`);
};
//前端版本监测
const checkVersion = () => {
  let version = localStorage.app_version;
  if (version) {
    version = JSON.parse(version);
    if (version.version === "init") {
      getVersion().then((res) => {
        localStorage.app_version = JSON.stringify({
          version: res.data.version,
          t: new Date().getTime(),
        });
      });
    } else if (new Date().getTime() - version.t > 10000) {
      getVersion().then((res) => {
        if (res.data.version !== version.version) {
          store.dispatch("user/changeVersion", true);
          localStorage.app_version = JSON.stringify({
            version: res.data.version,
            t: new Date().getTime(),
          });
        }
      });
    }
  } else {
    getVersion().then((res) => {
      localStorage.app_version = JSON.stringify({
        version: res.data.version,
        t: new Date().getTime(),
      });
      store.dispatch("user/changeVersion", true);
    });
  }
};

function setPageTitle(mode) {
  let data = "";
  const pageList = store.state.user.allSetupList;
  if (!pageList.length) return data;
  let PAGE_TITLE = pageList.filter((val) => {
    return val.mode === mode;
  });
  if (!PAGE_TITLE.length) return data;
  data = PAGE_TITLE[0].value;
  return data;
}

router.beforeEach(async (to, from, next) => {
  checkVersion();

  NProgress.start();
  document.title = getPageTitle(to.meta.title, setPageTitle("PAGE_TITLE"));

  const hasOrg =
    store.getters.allBusiness && store.getters.allBusiness.length > 0;

  const userType = store.getters.userType;

  const org = getBus();
  if(to.path.indexOf('/download')!=-1){
    next();
    NProgress.done();
    return
  }
  // debugger
  if (getToken()) {
    // 刷新后重新获取用户信息
    // if (!hasOrg) {
    //   if (to.meta.roles) { // 在个人中心
    //     let res = await store.dispatch('user/getInfo')
    //     let memberType = userType ? userType : res.memberDetailsVo.memberType
    //     if (to.meta.roles.includes(memberType)) {
    //       next()
    //     } else {
    //       Message.warning('无访问权限')
    //       next('/')
    //     }
    //   } else {
    //     next()
    //   }
    // }
    if (!getAccount() && to.path === "/") {
      await store.dispatch("user/getInfo");
    }

    const isBd = store.getters.userMobile;
    if (to.meta.requireAuth || to.path === "/") {
      if (isBd !== null && isBd !== "") {
        next();
        NProgress.done();
      } else {
        $message.error({
          message: "请先绑定手机号",
        });
        next({
          path: "/change-phone",
          query: { origin: "memberChangePhones" },
        });
        NProgress.done();
      }
    } else {
      if (to.path === "/login") {
        next({ path: "/" });
        NProgress.done();
      } else {
        next();
        NProgress.done();
      }
    }
  } else {
    if (!hasOrg) {
      let select = {};
      store
        .dispatch("user/getBusiness")
        .then((res) => {
          if (res.length) {
            // 刷新后重新获取已选商户
            select = org
              ? res.find((v) => v.orgId === org.orgId)
                ? res.find((v) => v.orgId === org.orgId)
                : res[0]
              : res[0];
            setBus(select);
          }
        })
        .then((_) => {
          if (to.path != "/login" || (to.path == "/login" && store.getters.header && Object.keys(store.getters.header).length>0)) {
             store.dispatch("user/changeBusiness", select);
          }
        });
    }
    if(to.path != "/login"  &&  store.getters.header && Object.keys(store.getters.header).length==0){
      let res = store.getters.allBusiness;
      let select = {};
      select = org
              ? res.find((v) => v.orgId === org.orgId)
                ? res.find((v) => v.orgId === org.orgId)
                : res[0]
              : res[0];
      store.dispatch("user/changeBusiness",select);
    }
    if (!to.meta.requireAuth) {
      // 无需登录权限，直接进入
      next();
      NProgress.done();
    } else {
      next("/login"); // 否则全部重定向到登录页
      $message.warning("请先登录");
      NProgress.done();
    }
  }
  next();
  NProgress.done();
});

router.afterEach(() => {
  NProgress.done();
});
