const getters = {
  buyersVo: state => state.user.buyersVo,
  org: state => state.user.org,
  license: state => state.user.license,
  userDetail: state => state.user.userDetail,
  token: state => state.user.token,
  userType: state => state.user.userType,
  allBusiness: state => state.user.allBusiness,
  allSetupList: state => state.user.allSetupList,
  // buyersVoList: state => state.user.buyersVoList,
  selectEttleAccountId: state => state.user.selectEttleAccountId,
  accountId: state => state.user.accountId,
  refreshModule: state => state.user.refreshModule,
  header: state => state.user.header,
  footer: state => state.user.footer,
  consignedDelivery: state => state.user.consignedDelivery,
  cartNum: state => state.user.cartNum,
  fromPath:state=>state.user.fromPath,
  productCategory: state => state.user.productCategory,
  userMobile:state=>state.user.userDetail.userMobile,
  showIndex:state=>state.user.showIndex,
  proxyEndDate: state=>state.user.proxyEndDate,
  allLoading: state=>state.user.allLoading,
  payParams:state=>state.user.payParams,
  btnBol:state=>state.user.btnBol,
};


export default getters
