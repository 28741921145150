import defaultSettings from '@/settings'
import store from "../store";

const title = defaultSettings.title || 'Vue Admin Template'

export default function getPageTitle(pageTitle,setTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${setTitle || title}`
  }
  return `${setTitle || title}`
}
export  function setPageTitle(mode) {
  let data = "";
  const pageList = store.state.user.allSetupList;
  if (!pageList.length) return data;
  let PAGE_TITLE = pageList.filter((val) => {
    return val.mode === mode;
  });
  if (!PAGE_TITLE.length) return data;
  data = PAGE_TITLE[0].value;
  return data;
}
