var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mask-box",
      style: {
        backgroundColor: _vm.opacity == 1 ? "#f5f5f5" : _vm.backgroundVal,
      },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mask-content" }, [
      _c("img", {
        staticClass: "mask-sign",
        attrs: { src: require("@/assets/img/decorate/loading-icon.png") },
      }),
      _c("img", {
        staticClass: "mask-cicle",
        attrs: { src: require("@/assets/img/decorate/loading-cicle.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }