<template>
    <div class="mask-box" >
         <div class="mask-content">
               <template v-if="cartType=='success'">
                  <img src="@/assets/img/detail/success.png" />
                  <div class="tip">{{tipsStr}}</div>
               </template>
               <template v-else>
                    <img src="@/assets/img/detail/fail.png" />
                    <div class="tip fail">{{tipsStr}}</div>
               </template>

         </div>
    </div>
  </template>
  
  <script>
    export default {
      name: 'app',
      data () {
        return {
          show: true
        }
      },
      props: {
        cartType:{
            type: String,
            default(){
                return ''
            }
        },
        tipsStr:{
            type: String,
            default(){
                return ''
            }
        },
        loadingBol: {
          type: Boolean,
          default(){
            return true
          }
        },
        opacity: {
          type: Number,
          default(){
            return 1
          }
        },
      },
      computed: {
          backgroundVal() {
            return `rgba(0, 0, 0, ${this.opacity})`;
          },
        },
      methods: {
      
      }
    }
  </script>
  
  <style lang="scss" scoped>
      .mask-box{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        // background-color: rgba(0,0,0,0.3);
        left: 0;
        top: 0;
        z-index: 9999;
        
        /* 定义动画 */
            @keyframes expandFromCenter {
                from {
                    transform: scale(0);
                    opacity: 0;
                }
                to {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        .mask-content{
        //   width: 230px;
        //   height: 145px;
        animation: expandFromCenter 0.3s forwards;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          box-shadow: 0 6px 48px 0 rgba(0,17,51,.12);
          border-radius: 16px;
          padding: 24px 45px;
          img{
             width: 40px;
             height: 40px;
          }
          .tip{
             margin-top: 25px;
             font-size: 17px;
             line-height: 20px;
             color: #11192d;
            //  min-width: 200px;
            max-width: 200px;
            text-align: center;
          }
          .fail{
            font-weight: bold;

          }
        }

     }
  </style>
  