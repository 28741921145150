import request from '@/utils/request'
import defaultSettings from '@/settings'


/**
 * @description 获取企业基本信息
 */
export function getCorporateBaseInfo(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/personalCenter/getBuyersByUserId`,
    method: 'get',
    data
  })
}
/**
 * @description 获取企业经营范围
 */
export function getCorporateBusinessRange(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/personalCenter/getBusinessRangeCategoryList`,
    method: 'get',
    data
  })
}
/**
 * @description 获取企业收货地址
 */
export function getCorporateReceiverAddr(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/personalCenter/getBuyersReceiverAddrByUserId`,
    method: 'get',
    data
  })
}
/**
 * @description 获取企业发票信息
 */
export function getCorporateInvoiceInfo(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/personalCenter/getInvoiceByUserId`,
    method: 'get',
    data
  })
}
/**
 * @description 获取账户信息
 */
export function getAccountInfo() {
  return request({
    url: `/${defaultSettings.businessPre}/pc/personalCenter/getUserEttleAccountRel`,
    method: 'GET',
  })
}
/**
 * @description 获取账户信息
 */
export function getMyAptitudes(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/personalCenter/getLicenseListByUserId`,
    method: 'get',
    data
  })
}

/**@description 修改手机号
 *
 */
export function changePhone(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/user/bindMobile`,
    method: 'post',
    data
  })
}

/** @description 获取账户资料
 *
 */
export function getUserInfo(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/user/getAccountInfo`,
    method: 'get',
    params
  })
}

/**
 * @description  保存采购商资质
 */
export function saveBuyersLicenseRel (data) {
  return request({
    url: `/${defaultSettings.businessPre}/buyersLicenseRel/saveBuyersLicenseRel`,
    method: 'POST',
    data
  })
}

/**
<<<<<<< HEAD
 * @description  保存采购商资质---法人委托书
 */
 export function saveAttorney (data) {
  return request({
    url: `/${defaultSettings.businessPre}/buyersAttorney/saveAttorney`,
    method: 'POST',
    data
  })
}


/**
 * @description 文件上传---法人委托书
 */
 export function buyersProxyUploadFile(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/personalCenter/buyersProxyUploadFile`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

/**
 * @description 获取法人委托书数据
 */
 export function getAttorney(data) {
  return request({
    url: `/${defaultSettings.businessPre}/buyersAttorney/getAttorney`,
    method: 'get',
    data
  })
}

export function selectNewBranchBuyers (buyersId,ettleAccountId) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/user/selectBranchBuyers`,
    method: 'get',
    params: {
      ettleAccountId:ettleAccountId,
      buyersUserId:buyersId
    }
  })
}

export function getMaintenanceBulletin(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/user/getMaintenanceBulletin`,
    method: 'get',
    data
  })
}
// 查询绑定微信状态
export function loginBind(myId) {
  return request({
    url: `/${defaultSettings.businessPre}/wechatPublic/loginBind?myId=${myId}`,
    method: 'get',
  })
}
// 解绑微信
export function loginUnBind(myId) {
  return request({
    url: `/${defaultSettings.businessPre}/wechatPublic/loginUnBind?myId=${myId}`,
    method: 'get',
  })
}
// 绑定码
export function getQrCode (data) {
  return request({
    url: `/${defaultSettings.businessPre}/wechatPublic/getQrCode`,
    method: 'POST',
    data
  })
}

// 登录时查询绑定微信状态

export function getUserBindPublic(myId) {
  return request({
    url: `/${defaultSettings.businessPre}/wechatPublic/getUserBindPublic?myId=${myId}`,
    method: 'get',
  })
}
