// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./rotate.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".magnifier-box[data-v-95f04c08] {\n  width: 100%;\n  height: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  overflow: hidden;\n  position: relative;\n}\n.magnifier-box .edit-wrap[data-v-95f04c08] {\n    position: absolute;\n    top: 5px;\n    right: 0;\n    z-index: 9999999;\n    background: rgba(0, 0, 0, 0.4);\n    padding: 5px 15px 0 15px;\n    border-radius: 15px;\n}\n.magnifier-box .edit-wrap .rotate-left[data-v-95f04c08] {\n      display: inline-block;\n      cursor: pointer;\n      width: 16px;\n      height: 16px;\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n      background-size: 100% 100%;\n      -webkit-transform: scaleX(-1);\n      transform: scaleX(-1);\n      /*IE*/\n      -webkit-filter: FlipH;\n              filter: FlipH;\n}\n.magnifier-box .edit-wrap .rotate-right[data-v-95f04c08] {\n      margin-left: 10px;\n      cursor: pointer;\n      display: inline-block;\n      width: 16px;\n      height: 16px;\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n      background-size: 100% 100%;\n}\n.magnifier-box img[data-v-95f04c08] {\n    width: 100%;\n}\n.magnifier-box .mouse-cover[data-v-95f04c08] {\n    position: fixed;\n    background-color: rgba(0, 0, 0, 0.5);\n    cursor: move;\n}\n.magnifier-box .mouse-cover-canvas[data-v-95f04c08] {\n    position: fixed;\n    left: 100%;\n    top: 0;\n    width: 100%;\n    height: 100%;\n}\n.magnifier-box.vertical img[data-v-95f04c08] {\n    height: 100%;\n    width: auto;\n}\n", ""]);
// Exports
module.exports = exports;
