<template>
  <div id="app"  :class="$route.path=='/'?'homApp':'otherApp'">
    <Header v-if="!whiteList.includes(key) && currentHref.indexOf('/download')==-1"></Header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Footer v-if="!whiteList.includes(key)" :class="$route.path=='/'?'orgin-footer-conponents':'footer-conponents'"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
export default {
  name: 'App',
  data () {
    return {
      whiteList: ['login','404','register','download'],
      currentHref:''
    }
  },
  components: {
    Header,
    Footer
  },
  computed: {
    key() {
      return this.$route.name
    }
  },
  watch:{
    '$store.state.user.isChangeVersion'(val){
        if(val === true){
          this.$confirm('商城更新了新内容，请刷新页面获取更新','提示',{
                cancelButtonText: '稍后自行刷新',
                showCancelButton: false,
                closeOnClickModal: false,
                showClose: false,
                confirmButtonText: '立即刷新',
          }).then( res =>{
            location.reload()
          }).catch( err =>{
            console.log('自行刷新')
          })
          this.$store.dispatch('user/changeVersion',false)

        }
    }
  },
  created(){
    this.currentHref = window.location.href
    if(window.location.href.indexOf('/download')==-1){
        this.$store.dispatch('user/pageSetupList')
    }
  }
};
</script>

<style lang="scss" scoped>
.homApp{
   zoom:1.14;
}
@media only screen and (max-width: 1900px) {
  .homApp{
   zoom:1.14
}
}
@media only screen and (max-width: 1518px) {
  .homApp{
      zoom:1.1;
   }
}
@media only screen and (max-width: 1468px) {
  .homApp{
      zoom:1.06;
   }
}
@media only screen and (max-width: 1390px) {
  .homApp{
      zoom:1.03;
   }
}

$multipleNum:1.1;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222;
    
}
::v-deep.orgin-footer-conponents{
  background: var(--main-fooot-background-color);
}
::v-deep.footer-conponents{
  background: var(--main-fooot-background-color);
}
::v-deep.orgin-footer-conponents{
  background: var(--main-fooot-background-color);
}
 ::v-deep.search-main{
      border-bottom: 2px solid var(--main-color) !important;
      background-color: #fff !important;
 }
 ::v-deep.nav-item-first{
      display: none  !important;
 }
 ::v-deep.nav-item-lastbox{
      background-color: transparent  !important;
 }
 ::v-deep.nav-item{
      height: 51px*$multipleNum !important;
 }
 ::v-deep.search{
      border: 2px solid var(--main-color)
 }
 ::v-deep.search-button{
      background-color: var(--main-color)
 }
</style>

