import Vue from 'vue';
import Loading from "@/components/Loading/Loading";
import tipMask from "@/components/tipMask/tipMask";
let loadingObj = null;

export function showLoading(data,type,tips) {
  if (!loadingObj) {
    if(type){
      loadingObj = new Vue({
        render: h => h(tipMask,{props:{opacity:data,cartType:type,tipsStr:tips}}),
      }).$mount();
    }else{
      loadingObj = new Vue({
        render: h => h(Loading,{props:{opacity:data}}),
      }).$mount();
    }
    
    document.body.appendChild(loadingObj.$el);
  }
}

export function hideLoading() {
  if (loadingObj) {
    document.body.removeChild(loadingObj.$el);
    loadingObj = null;
  }
}