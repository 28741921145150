var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "im-tool-bar" }, [
    _c("div", { staticClass: "im-tool-bar__item" }, [_vm._t("left")], 2),
    _c("div", { staticClass: "im-tool-bar__item" }, [_vm._t("right")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }