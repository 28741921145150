import request from '@/utils/request'
import defaultSettings from '@/settings'
import { getBus } from '@/utils/auth'

/**
 * @description 获取某个商品详情
 */
export function getCart(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/getSysCart`,
    method: 'get',
    params
  })
}

/**
 * @description 处理报错后的提交订单
 */
export function deleteRequestRedis() {
  return request({
    url: `/${defaultSettings.businessPre}/order/deleteRequestRedis`,
    method: 'GET'
  })
}

/**
 * @description 加入购物车
 */
export function addItem(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/addSysItem`,
    method: 'post',
    data
  })
}

/**
 * @description 修改购物车数量
 */
export function updateQuantity(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/updateSysQuantity`,
    method: 'post',
    data
  })
}

/**
 * @description 单选
 */
export function updateSelected(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/updateSysSelected`,
    method: 'post',
    data
  })
}

/**
 * @description 全选
 */
export function updateAllSelected(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/updateAllSysSelected`,
    method: 'get',
    params
  })
}

/**
 * @description 删除购物车某个
 */
export function removeItem(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/removeSysItem`,
    method: 'post',
    data
  })
}

/**
 * @description 清空购物车
 */
export function clearedSysCart(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/clearedSysCart`,
    method: 'get',
    params
  })
}

/**
 * @description 取消收藏
 */
export function delByUserIdAndProductId(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/productCollect/delByUserIdAndProductId?userId=` + getBus().buyersId + "&productId=" + data,
    method: 'DELETE'
  })
}

/**
 * @description 清除失效购物车项
 */
export function clearInvalidItem() {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/clearSysInvalidItem`,
    method: 'get'
  })
}

/**
 * @description 提交订单
 */
export function submitOrder(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/saveOrder?remark=` + params.remark,
    method: 'GET'
  })
}

/**
 * @description 修改收货地址
 */
export function updateReceiverAddr(id) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/updateReceiverAddr?buyersReceiverAddrId=` + id,
    method: 'GET',
    id
  })
}

/**
 * @description 修改结算方式
 */
export function updatePaymentWay(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/updateSysPaymentWay`,
    method: 'POST',
    data
  })
}

/**
 * @description 获取结算页
 */
export function getSettlement() {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/getSysSettlement`,
    method: 'GET'
  })
}
/**
 * @description 支付请求接口
 */
export function goBank(data) {
  return request({
    url: `/${defaultSettings.businessPre}/payment/goBank`,
    method: 'POST',
    data
  })
}
/**
 * @description 收银台-展示支付列表接口
 */
export function findEnablePaymentWay(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/paymentWay/findEnablePaymentWay`,
    method: 'GET',
    params
  })
}

/**
 * @description 修改优惠券
 */
export function updateCoupon(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/updateCoupon`,
    method: 'GET',
    params
  })
}

/**
 * @description 订单id查询
 */
export function getOrderInf(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/getOrderInf`,
    method: 'GET',
    params
  })
}


/**
 * @description 订单号查询
 */
export function getOrderInfOrderNum(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/getOrderInfOrderNum`,
    method: 'GET',
    params
  })
}
/**
 * @description 后台回调
 */
export function receive(url) {
  return request({
    url: `/${defaultSettings.businessPre}/payment/front/alipay/receive` + url,
    method: 'GET'
  })
}

/**
 * @description 购物车数量
 */
export function getAllProductTypeTotalQuantity() {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/getSysAllProductTypeTotalQuantity`,
    method: 'GET'
  })
}

/**
 * @description 根据SKUID获取productId
 */
export function getProductIdBySkuId(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/product/getProductIdBySkuId`,
    method: 'GET',
    params
  })
}

/**
 * @description 往来账支付
 */
export function currentBillsgoBank(data) {
  return request({
    url: `/${defaultSettings.businessPre}/payment/currentBillsgoBank`,
    method: 'post',
    data
  })
}

/**
 * @description 已完成付款
 */
export function getPayOkStatus(id) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/currentBills/getPayOkStatus?orderId=${id}`,
    method: 'GET'
  })
}

/**
 * @description 支付方式
 */
export function getPayChannel() {
  return request({
    url: `/${defaultSettings.businessPre}/pc/mchApp/getPayChannel`,
    method: 'GET'
  })
}



/**
 * @description 支付参数签名
 */
export function signPay(data) {
  return request({
    url: `/${defaultSettings.businessPre}/develop/trade/sign/pay`,
    method: 'POST',
    data
  })
}


/**
 * @description 支付接口
 */
export function unipay(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/unipay/pay`,
    method: 'post',
    data
  })
}


/**
 * @description 切换结款方式接口
 */
export function updatePayChannel(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/updatePayChannel`,
    method: 'post',
    data
  })
}



/**
 * @description 关闭/撤销参数签名接口
 */
export function signClose(data) {
  return request({
    url: `/${defaultSettings.businessPre}/develop/trade/sign/close`,
    method: 'post',
    data
  })
}


/**
 * @description 关闭/撤销接口
 */
export function unipayClose(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/unipay/close`,
    method: 'post',
    data
  })
}



/**
 * @description 关闭/撤销接口
 */
export function newGetPayOkStatus(orderId) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/getPayOkStatus?orderId=`+orderId,
    method: 'get',
  })
}


/**
 * @description 选择优惠券
 */
export function updateCartCoupon(couponId) {
  return request({
    url: `/${defaultSettings.businessPre}//pc/member/normalShoppingFlow/updateCartCoupon?couponId=`+couponId ,
    method: 'get',
  })
}


/**
 * @description 是否允许授信支付接口
 */
export function creditPayOrder(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/creditPayOrder`,
    method: 'post',
    data
  })
}
/**
 * @description 订单锁库下单
 */
export function saveOrderLocked(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/saveOrderLocked?remark=` + params.remark,
    method: 'GET'
  })
}


/**
 * @description 锁库订单中转
 */
export function getOrderProcess(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/getOrderProcess`,
    method: 'get',
    data
  })
}

/**
 * @description 中转提交订单
 */
export function saveOrderPull(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/saveOrderPull`,
    method: 'post',
    data
  })
}

