<template>
   <div class="empty-box">
      <div>
        <el-image style="width: 290px; height: 290px" :src="imgUrl" fit="fill"/>
        <p class="empty-tip">{{empytTip}}</p>
      </div>
    </div>

</template>
<script>
import emptyDefault from '@/assets/img/member-center/content-empty-default.png'
import listEmptyDefault from '@/assets/404_images/detail.png'
export default {
    props:{
       emptyType:{
        type: String,
        default:'normal'
       },
       empytTip:{
        type: String,
        default:'暂无信息~'
       },
       imgUrl:{
        type: String,
        default: emptyDefault
       }
    },
    data () {
      return {
          emptyDefault,
          listEmptyDefault
      }
    },
    mounted(){
       this.imgUrl = this.emptyType === 'normal' ? emptyDefault : listEmptyDefault
    }
}
</script>
<style scoped lang="scss">
     .empty-box {
      padding-top:80px;
      width:100%;
      height:auto;
      display: flex;
      justify-content: center;
      .empty-tip {
        text-align:center;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(113, 113, 113, 1);
        line-height: 20px;
      }
    }
</style>