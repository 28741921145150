import request from '@/utils/request'
import defaultSettings from '@/settings'

/**
 * @description 获取某个订单详情
 */
export function findDetail(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/findDetail`,
    method: 'get',
    params
  })
}

/**
 * @description 获取订单分页
 */
export function pageOrder(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/pageOrder`,
    method: 'post',
    data
  })
}

/**
 * @description 获取订单分页-查索引
 */
export function pageIndexOrder(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/pageIndexOrder`,
    method: 'post',
    data
  })
}

/**
 * @description 再次购买
 */
export function buyAgain(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/buyAgain`,
    method: 'get',
    params
  })
}
export function buyAgainSys(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/buyAgainSys`,
    method: 'get',
    params
  })
}

/**
 * @description 查询是否可以再次购买
 */
export function checkBuyAgain(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/normalShoppingFlow/isBuyAgain`,
    method: 'get',
    params
  })
}

/**
 * @description 全部支付方式
 */
export function allPaymentWay(params) {
  return request({
    url: `/${defaultSettings.businessPre}/paymentWay/allPaymentWay`,
    method: 'get',
    params
  })
}

/**
 * @description 付款方式
 */
export function listPaymentWay(params) {
  return request({
    url: `/${defaultSettings.businessPre}//pc/member/paymentWay/listPaymentWay`,
    method: 'get',
    params
  })
}


/**
 * @description 导出商品明细
 */
export function exportOrderItem(params) {
  return request({
    url: `/${defaultSettings.businessPre}/orderItem/exportOrderItemExcel`,
    method: 'get',
    responseType: 'blob',
    params
  })
}

/**
 * @description 确认收货
 */
export function comfirmOrder(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/comfirmOrder`,
    method: 'get',
    params
  })
}

/**
 * @description 下载药检单
 */
export function downloadSheet(data) {
  return request({
    url: `/fileService/fileLib/upload/downloadZipFiles`,
    method: 'post',
    responseType: 'blob',
    data
  })
}

/**
 * @description 订单对应的药检单
 */
export function pageOrderDrugList(data) {
  return request({
    url: `/${defaultSettings.businessPre}/drugList/front/pageOrderDrugList`,
    method: 'post',
    data
  })
}

/**
 * @description 确认订单
 */
export function comfirmTheOrder(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/comfirmTheOrder`,
    method: 'get',
    params
  })
}

/**
 * @description 根据订单ID获取往来账单
 */
export function currentBills(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/currentBills/getCurrentBillsByOrderId`,
    method: 'get',
    params
  })
}

/**
 * @description 取消订单
 */
export function cancelOrder(params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/cancelOrder`,
    method: 'get',
    params
  })
}

/**
 * @description 电子发票
 */
export function downloadInvoice(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/currentBills/upload/downloadZipFiles`,
    method: 'post',
    responseType:'blob',
    data
  })
}
/**
* @description 物流信息
*/
export function findWmsLogistics(params) {
 return request({
   url: `/${defaultSettings.businessPre}/order/findWmsLogistics`,
   method: 'get',
   params
 })
}

/*@@
* 药检单列表
* */
export function pageOrgIdDrugList(data) {
  return request({
    url: `/${defaultSettings.businessPre}/drugList/front/pageOrgIdDrugList`,
    method: 'POST',
    data
  })
}

/**
 * @description 获取订单状态数量
 */
 export function memberDetailspOrderNum() {
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/memberDetailspOrderNum`,
    method: 'get'
  })
}


/**
 * @description 获取订单状态数量（支持查询）
 */
export function getOrderPageCount(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/getOrderPageCount`,
    method: 'post',
    data
  })
}