<template>
  <el-drawer
    class="im-drawer"
    :visible.sync="drawVisible"
    direction="rtl"
    :show-close="false"
    :size="size"
    :modal="modal"
    :before-close="beforeClose"
    :wrapper-closable="wrapperClosable"
    :append-to-body="appendToBody"
  >
    <template slot="title">
      <slot name="title">
        <span class="drawer-title-txt">{{ title }}</span>
        <el-button
          v-if="showClose"
          class="drawer-head-close"
          icon="el-icon-close"
          type="text"
          @click="handleCancel"
        />
      </slot>
    </template>
    <slot />
    <el-row v-if="hasFooter" class="drawer-handle-btns">
      <slot name="footer">
        <el-button @click="handleCancel">{{cancelBtnText}}</el-button>
        <el-button type="primary" @click="handleConfirm" :disabled="handleNoDis" v-throttle>{{confirmBtnText}}</el-button>
      </slot>
    </el-row>
  </el-drawer>
</template>
<script>
export default {
  name: 'ImDrawer',
  props: {
    confirmBtnText:{
      type:String,
      default:'确定'
    },
    cancelBtnText:{
      type:String,
      default:'取消'
    },
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '40%'
    },
    hasFooter: {
      type: Boolean,
      default: true
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    beforeClose: {
      type: Function, // function(done),done用于关闭弹窗
      default: function(done) {
        done()
      }
    },
    showClose: {
      type: Boolean,
      default: true
    },
    modal: {
      type: Boolean,
      default: true
    },
    wrapperClosable: {
      type: Boolean,
      default: true
    },
    handleNoDis: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    drawVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
      this.$emit('update:visible', false)
    },
    handleConfirm() {
      this.$emit('confirm')
    }
    // beforeClose(done) {
    //   done()
    // }
  }
}
</script>
<style lang="scss">
.im-drawer {
  .el-drawer__body {
    height: calc(100vh - 110px);
    overflow-y: auto;
    padding-bottom: 55px;
  }
  .drawer-handle-btns {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 11px 24px;
    text-align: right;
    background: #fff;
    z-index: 9;
  }
}
</style>
