import request from '@/utils/request'
import defaultSettings from '@/settings'
/**
 * @description 登录接口
 * @param {Object} params 请求头部
 * @param {String} params.code 	验证码
 * @param {String} params.randomStr 获取验证码所用随机字符串
 * @param {Object} data 请求参数
 * @param {String} data.loginId 登录账号
 * @param {String} data.password 登录密码,前端必须加密
 */
export function login(params, data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/user/login`,
    method: 'post',
    headers: {
      'x-validate-code': params.code,
      'x-random-str': params.randomStr
    },
    data
  })
}

export function getInfo() {
  return request({
    url: `/${defaultSettings.businessPre}/pc/member/user/getFrontUserInfoVo`,
    method: 'get',
    notCancelXhr: true
  })
}

export function getOrderNumDetail(){
  return request({
    url: `/${defaultSettings.businessPre}/pc/order/memberDetailspOrderNum`,
    method: 'get'
  })
}

export function logout() {
  console.log("....logout")
  return request({
    url: '/vue-admin-template/user/logout',
    method: 'delete'
  })
}

export function phoneVerif(val) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/user/loginByMobile`,
    method: 'post',
    data: {
      'loginMobileId': val.phone,
      'validateCode': val.sms
    }
  })
}

export function phoneVerifCode(val, code) {
  return request({
    url: `/${defaultSettings.businessPre}/smsQueue/front/savePlatformSmsQueue`,
    method: 'post',
    headers: {
      'x-merchant-code-header': code
    },
    data: { ...val }
  })
}

export function getSmsAccountInfState() {
  return request({
    url: `/${defaultSettings.businessPre}/smsAccountInf/front/getSmsAccountInfState`,
    method: 'GET'
  })
}

export function findByProductIds(params) {
  return request({
    url: `/${defaultSettings.businessPre}/productLicenseRel/front/findByProductIds`,
    method: 'GET',
    params
  })
}

/**
 * @description 批量下载商品资质
 */
export function downloadPdfZip(data) {
  return request({
    url: `/${defaultSettings.businessPre}/productLicenseRel/upload/downloadPdfZip`,
    method: 'POST',
    data
  })
}

/**
 * @description 修改密码
 */
 export function putPsw(data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/user/putPsw`,
    method: 'POST',
    data
  })
}

/**
 * @description 修改密码时间
 */
 export function checkPadTime(data) {
    return request({
        url: `/${defaultSettings.businessPre}/pc/front/user/checkPadTime`,
        method: 'POST',
        data
    })
}
