import Vue from 'vue';
import Loading from "@/components/Loading/Loading";
let loadingObj = null;

export function showLoading(data) {
  if (!loadingObj) {
    loadingObj = new Vue({
      render: h => h(Loading,{props:{opacity:data}}),
    }).$mount();
    document.body.appendChild(loadingObj.$el);
  }
}

export function hideLoading() {
  if (loadingObj) {
    document.body.removeChild(loadingObj.$el);
    loadingObj = null;
  }
}