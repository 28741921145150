var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.$route.path == "/" ? "homApp" : "otherApp",
      attrs: { id: "app" },
    },
    [
      !_vm.whiteList.includes(_vm.key) &&
      _vm.currentHref.indexOf("/download") == -1
        ? _c("Header")
        : _vm._e(),
      _c(
        "keep-alive",
        [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
        1
      ),
      !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
      !_vm.whiteList.includes(_vm.key)
        ? _c("Footer", {
            class:
              _vm.$route.path == "/"
                ? "orgin-footer-conponents"
                : "footer-conponents",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }