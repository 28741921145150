// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* element variables*/\n/* theme color */\n.im-search-pad {\n  margin-bottom: 16px;\n  background-color: #fff;\n  padding: 16px 16px 0;\n}\n.im-search-pad .el-form-item {\n    margin-bottom: 16px;\n}\n.im-search-pad .isSearchBottom {\n    width: 100%;\n    padding: 10px 0;\n}\n.im-search-pad .isSearchBottom .bottom-content {\n      width: 100%;\n      display: -webkit-box;\n      display: -ms-flexbox;\n      display: flex;\n      -webkit-box-pack: center;\n          -ms-flex-pack: center;\n              justify-content: center;\n      padding-top: 10px;\n      border-top: 1px solid #ccc;\n}\n.theme-btn {\n  background-color: var(--main-background-color) !important;\n  border: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "208px"
};
module.exports = exports;
