import request from '@/utils/request'
import defaultSettings from '@/settings'
import { getBus } from '@/utils/auth'

/**
 * @description 获取某个装修模块的信息
 */
export function getPageModuleMap (data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/pageModule/getPageModuleMap`,
    method: 'post',
    async: false,
    notCancelXhr:true,
    data
  })
}

/**
 * @description 获取限时折扣最近一个活动
 */
export function getDiscountRuleStart (params) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/discountRule/getDiscountRuleStart`,
    method: 'get',
    params
  })
}

/**
 * @description 获取某个限时折扣详情
 */
export function pageDiscountProductRel (data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/discountRule/pageDiscountProductRel`,
    method: 'post',
    data
  })
}

/**
 * @description 获取优惠券
 */
export function pageCouponList (data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/coupon/pageCouponList`,
    method: 'post',
    data
  })
}

/**
 * @description 领取优惠券
 */
export function saveCoupon (data) {
  return request({
    url: `/${defaultSettings.businessPre}/pc/couponReceiveSituation/saveCouponReceiveSituation`,
    method: 'post',
    data
  })
}

/**
 * @description 为你推荐列表
 */
export function recommendToList () {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/product/recommendToYou`,
    method: 'GET'
  })
}

/**
 * @description 为你推荐手动设置列表
 */
export function pageRecommend (data) {
  return request({
    url: `/${defaultSettings.businessPre}/recommendProduct/front/pageRecommend`,
    method: 'POST',
    data
  })
}


/**
 * @description 资讯分类集合树
 */
export function getSubArticleCategoryByCode () {
  return request({
    url: `/${defaultSettings.businessPre}/pc/infArticle/front/getSubArticleCategoryByCode`,
    method: 'get',
    params: {
      categoryCode: '000001',
      org: getBus().orgId
    }
  })
}

/**
 * @description 商品分类
 */
export function categoryTree () {
  return request({
    url: `/${defaultSettings.businessPre}/pc/front/category/categoryTree`,
    method: 'GET',
    params: {
      orgId: getBus().orgId
    }
  })
}

/**
 * @description 商品分类
 */
export function addInfArticleViews(id) {
  return request({
    url: `/${defaultSettings.businessPre}/infArticle/front/addInfArticleViews?infArticleId=` + id,
    method: 'GET',
    id
  })
}


/**
 * @description apk下载
 */
export function getLatestVersion() {
  return request({
    url: `/${defaultSettings.businessPre}/app/front/getLatestVersion`,
    method: 'GET',
  })
}
