var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: _vm.id,
      staticClass: "magnifier-box",
      class: _vm.vertical ? "vertical" : "",
      on: {
        mousemove: _vm.mousemove,
        mouseover: _vm.mouseover,
        mouseleave: _vm.mouseleave,
      },
    },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showImg,
            expression: "showImg",
          },
        ],
        attrs: { src: _vm.imgUrl, alt: "" },
        on: { error: _vm.error },
      }),
      _c("div", { staticClass: "mouse-cover" }),
      _vm.showEidt
        ? _c("div", { staticClass: "edit-wrap" }, [
            _c("span", {
              staticClass: "rotate-left",
              on: {
                click: function ($event) {
                  return _vm.rotate("left")
                },
              },
            }),
            _c("span", {
              staticClass: "rotate-right",
              on: {
                click: function ($event) {
                  return _vm.rotate("right")
                },
              },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }